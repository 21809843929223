import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { ExternalLink, PriceDisplay, RenderedHTML } from 'components'
import { noop } from 'lodash'
import { Link } from 'react-router'
import classnames from 'classnames'
import { getHtmlTextLength } from 'utils'

const propTypes = {
  active: PropTypes.bool,
  activeButtonText: PropTypes.string,
  buttonText: PropTypes.string,
  descriptionHtml: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  learnMoreLink: PropTypes.string,
  path: PropTypes.string,
  price: PropTypes.number,
  pricePhrase: PropTypes.string,
  maxTextLength: PropTypes.number,
  showReadMoreModal: PropTypes.func,
  currentMembershipLabel: PropTypes.string,
}

const defaultProps = {
  active: false,
  activeButtonText: 'Remove',
  buttonText: 'Select Item',
  descriptionHtml: '',
  imageUrl: '',
  learnMoreLink: '',
  path: '',
  price: null,
  pricePhrase: '',
  onClick: noop,
  selectRef: null,
  maxTextLength: 375,
  showReadMoreModal: noop,
  currentMembershipLabel: '',
}

// eslint-disable-next-line
function ClickableContainer({ path, children, onClick, expanded }) {
  // Wrap container in an anchor if a path is provided
  if (path) return <Link to={path}>{children}</Link>
  return (
    <button type="button" aria-expanded={expanded} onClick={onClick}>
      {children}
    </button>
  )
}

function HorizontalCard({
  active,
  activeButtonText,
  buttonText,
  descriptionHtml,
  displayName,
  imageUrl,
  learnMoreLink,
  onClick,
  path,
  price,
  pricePhrase,
  maxTextLength,
  showReadMoreModal,
  currentMembershipLabel,
}) {
  const shouldShowReadMore = useMemo(
    () => maxTextLength < getHtmlTextLength(descriptionHtml),
    [maxTextLength, descriptionHtml]
  )
  return (
    <React.Fragment>
      <div className="left-content">
        <ClickableContainer expanded={active} path={path} onClick={onClick}>
          {!!currentMembershipLabel && (
            <p className="banner-label">{currentMembershipLabel}</p>
          )}
          <div className="image">
            {imageUrl && <img src={imageUrl} alt="" />}
          </div>
          <span
            className={classnames('button-primary', {
              'button-selected': active,
            })}
            onClick={onClick}
            aria-label={
              active ? `remove ${displayName}` : `select ${displayName}`
            }
          >
            {active ? activeButtonText : buttonText}
          </span>
        </ClickableContainer>
      </div>
      <div className="right-content">
        <div className="right-content-overflow">
          <h3>{displayName}</h3>
          <PriceDisplay price={price} phrase={pricePhrase} />
          <RenderedHTML className="item-copy">{descriptionHtml}</RenderedHTML>
          {learnMoreLink && (
            <ExternalLink
              href={learnMoreLink}
              className="link"
              aria-label={`Learn more about the ${displayName} product`}
            >
              Learn More
            </ExternalLink>
          )}
        </div>
        {shouldShowReadMore && (
          <div className="read-more-container">
            <button
              onClick={() =>
                showReadMoreModal({
                  heading: displayName,
                  htmlContent: descriptionHtml,
                })
              }
              className="read-more-link link-primary"
              aria-label={`Read more about the ${displayName} product`}
            >
              Read More
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

HorizontalCard.propTypes = propTypes
HorizontalCard.defaultProps = defaultProps

export default pure(HorizontalCard)
