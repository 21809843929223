import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SelectTicket as BaseSelectTicket } from 'base/ticketing/views'
import mysticPassImage from 'images/background/mystic_pass_card_2020.jpg'

const propTypes = {}

const defaultProps = {}

function SelectTicket() {
  return (
    <BaseSelectTicket>
      <div className="horizontal-item">
        <div className="left-content">
          <div className="image">
            <img src={mysticPassImage} alt="" />
          </div>
          <a
            className="button-primary"
            href="https://ramp.mysticaquarium.org/packages/flex/360/c7f151e0452bf7a05a5b1b8475855dff"
          >
            Buy Mystic Pass
          </a>
        </div>
        <div className="right-content">
          <div className="right-content-overflow">
            <h3>Also Visiting Mystic Aquarium?</h3>
            <div className="item-copy">
              <p>
                Buy a Mystic Pass Card - our joint ticket with Mystic Aquarium -
                and save big on admission to both attractions, plus enjoy great
                discounts around town!
              </p>
            </div>
          </div>
        </div>
      </div>
    </BaseSelectTicket>
  )
}

SelectTicket.propTypes = propTypes
SelectTicket.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SelectTicket)
