import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { modifyProps, onMount, waitFor } from 'lp-hoc'
import * as actions from '../actions.js'
import * as apiActions from 'api-actions'
import {
  CouponCode,
  StickyContainer,
  MainHeader,
  Receipt,
  AddOnProduct,
} from 'components'
import { BillingDetailsForm } from 'forms'
import { selectors } from '../reducer'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { isEmpty, includes } from 'lodash'
import { getPaymentProcessor } from 'payment'
import { selectors as globalSelectors } from 'global-reducer'
import * as LS from 'local-storage'
import { Spinner, Modal } from 'lp-components'
import { flashInvalidFormSubmitMessage, mapAdditionalCartFields } from 'utils'
import GTM from 'google-tag-manager'

const propTypes = {
  cart: PropTypes.object.isRequired,
  hidePaymentDetails: PropTypes.bool.isRequired,
  submitOrder: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  selectedTicket: PropTypes.object.isRequired,
  paymentProcessor: PropTypes.object.isRequired,
  currentStepName: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
  addOrRemoveModalProduct: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
}

const defaultProps = {}

function TicketPaymentInfo({
  cart,
  hidePaymentDetails,
  webstore,
  nextStepPath,
  push,
  submitOrder,
  validateCoupon,
  removeCoupon,
  replaceCart,
  selectedTicket,
  currentStepName,
  paymentProcessor,
  clearMessages,
  addOrRemoveModalProduct,
  organization,
}) {
  const modals = selectedTicket.modals
  const productModalData =
    modals &&
    modals.find(
      (m) =>
        m.location === Types.addOnProductModalLocations.PAYMENT_PAGE &&
        includes(Types.addOnProductModalTypes, m.modalType)
    )

  const [showModal, setShowModal] = useState(false)
  const requireConnecticutZip =
    organization.slug === 'mystic-seaport' &&
    selectedTicket.requireConnecticutZip
  useEffect(() => {
    if (productModalData && !sessionStorage.getItem('ticketingModalViewed')) {
      setTimeout(() => {
        sessionStorage.setItem('ticketingModalViewed', true)
        setShowModal(true)
      }, Types.modalSettings.TIMEOUT)
    }
  }, [productModalData])

  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName} />
        <div className="row">
          <div className="col-8">
            <BillingDetailsForm
              name={Types.billingFormTypes.TICKETING}
              requireConnecticutZip={requireConnecticutZip}
              collectRaceEthnicity={selectedTicket.collectRaceEthnicity}
              hidePaymentDetails={hidePaymentDetails}
              paymentProcessor={paymentProcessor}
              onSubmit={(params) => {
                clearMessages()
                return submitOrder(params)
              }}
              onSubmitSuccess={() => {
                push(`/${webstore}/tickets/${nextStepPath}`)
              }}
              onSubmitFail={flashInvalidFormSubmitMessage}
            />
          </div>
          {productModalData && showModal && (
            <Modal onClose={() => setShowModal(false)}>
              <AddOnProduct
                productModalData={productModalData}
                cart={cart}
                addOrRemoveModalProduct={addOrRemoveModalProduct}
                isInModal={true}
                hideModal={() => setShowModal(false)}
              />
            </Modal>
          )}
          <StickyContainer className="col-4">
            <Receipt
              title={selectedTicket.displayName}
              image={selectedTicket.image}
              cart={cart}
              displayTotal
            />
            <CouponCode
              coupon={cart.coupon}
              cartItems={cart.listItems}
              validateCoupon={validateCoupon}
              removeCoupon={removeCoupon}
              replaceCart={replaceCart}
            />
            {productModalData && (
              <AddOnProduct
                productModalData={productModalData}
                addOrRemoveModalProduct={addOrRemoveModalProduct}
                cart={cart}
              />
            )}
          </StickyContainer>
        </div>
      </div>
    </div>
  )
}

TicketPaymentInfo.propTypes = propTypes
TicketPaymentInfo.defaultProps = defaultProps

const checkCartForTicket = ({ cart, flashErrorMessage, push, webstore }) => {
  if (!cart || !cart.productId) {
    flashErrorMessage('Please select a ticket below', { timeout: 6000 })
    return push(`/${webstore}/tickets`)
  }

  if (cart.productId && isEmpty(cart.priceSelections)) {
    flashErrorMessage('Please add at least one ticket', { timeout: 6000 })
    return push(`/${webstore}/tickets/details/${cart.productId}`)
  }
}

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    hidePaymentDetails: selectors.hidePaymentDetails(state),
    webstore: globalSelectors.webstore(state),
    organization: globalSelectors.organization(state),
    selectedTicket: selectors.selectedTicket(state),
    nextStepPath: selectors.nextStepPath(state),
    currentStepName: selectors.currentStepName(state),
  }
}

const mapDispatchToProps = {
  setTicketConfirmation: actions.setTicketConfirmation,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  push: routerActions.push,
  emptyCart: actions.emptyCart,
  clearTicketTypes: actions.clearTicketTypes,
  clearSelectedTicket: actions.clearSelectedTicket,
  validateCoupon: apiActions.validateTicketCoupon,
  replaceCart: actions.replaceTicketingCart,
  removeCoupon: apiActions.removeTicketCoupon,
  updateCartPayment: apiActions.updateCartPayment,
  createTicketOrder: apiActions.createTicketOrder,
  addOrRemoveModalProduct: apiActions.addOrRemoveModalProduct,
}

const initializeOrgHelpers = ({ organization }) => {
  return {
    paymentProcessor: getPaymentProcessor(organization.paymentConfig.service),
  }
}

const initializePaymentProcessor = ({
  hidePaymentDetails,
  paymentProcessor,
  organization,
}) => {
  if (hidePaymentDetails) return
  return paymentProcessor.init(
    organization.paymentConfig.envVariables.environmentKey
  )
}

function modify({
  setTicketConfirmation,
  cart,
  emptyCart,
  clearTicketTypes,
  paymentProcessor,
  updateCartPayment,
  createTicketOrder,
  selectedTicket,
}) {
  return {
    submitOrder: (params) => {
      return paymentProcessor
        .submitPaymentDetails(cart.total, params.paymentDetails)
        .then(({ cardReference, cardType, lastFourDigits }) =>
          updateCartPayment({
            customerDetails: {
              ...params.customerDetails,
              cardType,
              lastFourDigits,
            },
            cardReference,
            cart: mapAdditionalCartFields(cart, params),
          })
        )
        .then(createTicketOrder)
        .then((confirmation) => {
          GTM.sendTransactionData(confirmation.attributes, {
            productName: selectedTicket.displayName,
          })
          setTicketConfirmation(confirmation)
        })
        .then(() => {
          LS.clearCartToken()
          emptyCart()
          clearTicketTypes()
        })
    },
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers),
  onMount(initializePaymentProcessor),
  onMount(checkCartForTicket),
  waitFor('organization', Spinner),
  modifyProps(modify)
)(TicketPaymentInfo)
