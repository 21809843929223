import {
  createRequest,
  createPostRequest,
  createStubRequest,
} from 'lp-redux-api'
import {
  mapGeneralAdmissionTicketOrder,
  mapUpdateMembershipQuantities,
  getExcludedDatesFromTimeSlots,
} from 'utils'
import * as Types from 'types'
import { getConfigForOrg } from '../orgConfigs'
import { HttpError } from 'lp-requests'
import { format } from 'date-fns'
import { get, first, isEmpty } from 'lodash'

// ----- FOR DEVELOPMENT PURPOSES ONLY -----
// import { camelizeKeys } from 'humps'
// import memberAccount from '../../../fixtures/memberAccount'
// const camelizedMemberResponse = camelizeKeys(memberAccount)
// export const fetchMembershipAccount = stubRequest('FETCH_MEMBERSHIP_ACCOUNT', () => camelizedMemberResponse)
// export const requestMemberLogin = stubRequest('MEMBER_LOGIN', () => camelizedMemberResponse)

// ------ GENERAL ------

export const fetchOrganization = createRequest('FETCH_ORGANIZATION', () => {
  return {
    url: '/current_organization',
  }
})

export const verifyReCaptcha = createPostRequest(
  'VERIFY_RECAPTCHA',
  (captchaResponse) => {
    return {
      root: process.env.API_URL,
      url: '/verify',
      body: {
        captchaResponse,
      },
      onFailure: (e) => {
        // Service will return a 422 if validation of the token fails
        if (e && e.status === 422)
          return { errors: { recaptcha: 'Verification failed.' } }

        return e
      },
    }
  }
)

export const checkOrderIpStatus = createRequest(
  'CHECK_ORDER_IP_ADDRESS',
  () => ({
    url: '/ip_status',
    onFailure: () => ({
      errors: {
        _error: `Oops! Looks like you've recently submitted an order. If this is a valid, new order, please try again in a few minutes.`,
      },
    }),
  })
)

// ------ TICKETING ------

export const fetchProducts = createRequest(
  'FETCH_PRODUCTS',
  ({ navigationCategory }) => {
    return {
      url: 'ticketing/products',
      query: { navigationCategory },
    }
  }
)

export const fetchTicket = createRequest('FETCH_TICKET', (id) => {
  return {
    url: `/ticketing/products/${id}`,
  }
})

export const addTicketToCart = createPostRequest(
  'ADD_TICKET_TO_CART',
  (productId, offerId, startDate) => {
    const selectedDate = startDate || new Date()
    return {
      url: `/ticketing/cart`,
      successDataPath: 'data',
      body: {
        cart: {
          productId,
          offerId,
          startDate: selectedDate,
          endDate: selectedDate,
          addOnSelections: [],
          priceSelections: [],
        },
      },
    }
  }
)

export const updateTicketCartPriceSelections = createPostRequest(
  'UPDATE_TICKET_CART_PRICE_SELECTIONS',
  (cart, priceSelections) => {
    return {
      url: `/ticketing/cart`,
      body: {
        cart: {
          ...cart,
          priceSelections,
        },
      },
    }
  }
)

export const updateTicketCartAddOnSelections = createPostRequest(
  'UPDATE_TICKET_CART_ADD_ON_SELECTIONS',
  (cart, addOnSelections) => {
    return {
      url: `/ticketing/cart`,
      body: {
        cart: {
          ...cart,
          addOnSelections,
        },
      },
    }
  }
)

export const fetchTicketTypes = createRequest(
  'FETCH_TICKET_TYPES',
  (productId, offerId) => {
    return {
      url: `/ticketing/products/${productId}/general_admission_offers/${offerId}`,
    }
  }
)

export const fetchTicketTimes = createRequest(
  'FETCH_TICKET_TIMES',
  (productId, startDate) => {
    return {
      url: `/ticketing/products/${productId}/timed_offers`,
      query: { startDate },
    }
  }
)

export const fetchExcludedDates = createRequest(
  'REQ_EXCLUDED_DATES',
  (productId, { startDate, endDate } = {}) => {
    return {
      url: `/ticketing/products/${productId}/timed_offers`,
      query: {
        startDate: startDate ? format(startDate, 'YYYY-MM-DD') : null,
        endDate: endDate ? format(endDate, 'YYYY-MM-DD') : null,
        excludeTickets: true,
      },
      onSuccess: (timeSlots) => {
        // Transform the response (time slots) into the response we want (excluded dates)
        const excludedDates = getExcludedDatesFromTimeSlots(
          timeSlots,
          startDate,
          endDate
        )
        return { productId, excludedDates, startDate, endDate }
      },
    }
  }
)

export const fetchAddOnTicketTimes = createRequest(
  'FETCH_ADD_ON_TICKET_TIMES',
  (productId, startDate) => {
    return {
      url: `/ticketing/products/${productId}/timed_offers`,
      query: { startDate },
    }
  }
)

export const fetchTimedAddOnTimes = createRequest(
  'FETCH_TIMED_ADD_ON_TIMES',
  (addOnId, startDate) => {
    return {
      url: `/ticketing/products/${addOnId}/timed_offers`,
      query: { startDate },
      onSuccess: (times) => ({ addOnId, times }),
    }
  }
)

export const fetchTimedAddOnPrices = createRequest(
  'FETCH_TIMED_ADD_ON_PRICES',
  (addOnId, startDate, offerId) => {
    return {
      url: `ticketing/products/${addOnId}/timed_offers/${offerId}`,
      query: { startDate },
      onSuccess: ({ prices }) => ({ prices, addOnId }),
    }
  }
)

export const fetchExtraPrices = createRequest(
  'FETCH_EXTRA_PRICES',
  (selectedTicketId, offerId, extraProductId) => {
    return {
      url: `/ticketing/products/${selectedTicketId}/timed_offers/${offerId}/extra_prices/${extraProductId}`,
    }
  }
)

export const fetchTimedTicketTypes = createRequest(
  'FETCH_TIMED_TICKET_TYPES',
  (productId, startDate, centamanId) => {
    return {
      url: `ticketing/products/${productId}/timed_offers/${centamanId}`,
      query: { startDate },
    }
  }
)

export const fetchAddOnTimedTickets = createRequest(
  'FETCH_ADD_ON_TIMED_TICKETS',
  (productId, startDate, offerId) => {
    return {
      url: `ticketing/products/${productId}/timed_offers/${offerId}`,
      query: { startDate },
    }
  }
)

export const fetchAddOnTicketTypes = createRequest(
  'FETCH_ADD_ON_TICKET_TYPES',
  (productId, startDate, offerId) => {
    return {
      url: `ticketing/products/${productId}/timed_offers/${offerId}`,
      query: { startDate },
    }
  }
)

export const fetchAddOnOffers = createRequest(
  'FETCH_ADD_ON_OFFERS',
  (productId) => {
    return {
      url: `/ticketing/products/${productId}`,
    }
  }
)

export const fetchTicketCart = createRequest('FETCH_TICKET_CART', () => {
  return {
    url: '/ticketing/cart',
  }
})

export const validateTicketCoupon = createPostRequest(
  'VALIDATE_TICKET_COUPON',
  ({ coupon }) => ({
    url: `/ticketing/coupons/validate`,
    body: {
      coupon,
    },
    onFailure: ({ errors }) => ({
      errors: {
        coupon: errors.message || 'This coupon is invalid',
        cart: errors.ticketingCart,
      },
    }),
  })
)

export const removeTicketCoupon = createPostRequest(
  'REMOVE_TICKET_COUPON',
  () => ({
    url: `/ticketing/cart`,
    body: {
      cart: {
        coupon: null,
      },
    },
  })
)

export const updateTicketCart = createPostRequest(
  'UPDATE_TICKET_CART',
  (cart, ticketingForm) => ({
    url: `/ticketing/cart`,
    body: {
      cart: mapGeneralAdmissionTicketOrder(cart, ticketingForm),
    },
    successDataPath: 'data',
  })
)

export const updateCartPayment = createPostRequest(
  'UPDATE_CART_PAYMENT',
  ({ customerDetails, cardReference, cart }) => ({
    url: `/ticketing/cart`,
    body: {
      cart: {
        ...cart,
        customerDetails,
        cardReference,
      },
    },
    successDataPath: 'data',
  })
)

export const createTicketOrder = createPostRequest(
  'CREATE_TICKET_ORDER',
  () => ({
    url: `/ticketing/orders`,
    body: {},
    successDataPath: 'data',
  })
)

// ------ GIFT MEMBERSHIP (gift cards) ------

export const fetchGiftMemberships = createRequest(
  'FETCH_GIFT_MEMBERSHIPS',
  () => {
    return {
      url: `/giftcards/products`,
      query: { giftcardType: 'membership' },
    }
  }
)

export const fetchGiftMembershipCart = createRequest(
  'FETCH_GIFT_MEMEBRSHIP_CART',
  () => {
    return {
      url: '/giftcards/cart',
    }
  }
)

export const updateGiftMembershipCart = createPostRequest(
  'UPDATE_GIFT_MEMBERSHIP_CART',
  (cart, { priceSelectionsWithAttendeeDetails = [], ...params }) => {
    return {
      url: '/giftcards/cart',
      body: {
        cart: {
          ...cart,
          ...params,
          // Filter out quantities of zero.
          priceSelections: priceSelectionsWithAttendeeDetails.filter(
            (priceSelection) => priceSelection.quantity > 0
          ),
        },
      },
      successDataPath: 'data',
    }
  }
)

export const updateGiftMembershipPriceSelections = createPostRequest(
  'UPDATE_GIFT_MEMBERSHIP_PRICE_SELECTIONS',
  (cart, priceSelections) => {
    return {
      url: `/giftcards/cart`,
      body: {
        cart: {
          ...cart,
          productId: get(first(priceSelections), 'productId'), // Pass the first product ID so the API doesn't complain
          // Filter out quantities of zero.
          priceSelections: priceSelections.filter(
            (priceSelection) => priceSelection.quantity > 0
          ),
        },
      },
      successDataPath: 'data',
    }
  }
)

export const updateGiftMembershipCartPayment = createPostRequest(
  'UPDATE_GIFT_MEMBERSHIP_CART_PAYMENT',
  ({ customerDetails, cardReference, cart }) => {
    return {
      url: `/giftcards/cart`,
      body: {
        cart: {
          ...cart,
          customerDetails,
          cardReference,
        },
      },
    }
  }
)

export const createGiftMembershipOrder = createPostRequest(
  'CREATE_GIFT_MEMBERSHIP_ORDER',
  () => {
    return {
      url: `/giftcards/orders`,
    }
  }
)

// ------ MEMBERSHIP ------

export const fetchMemberships = createRequest('FETCH_MEMBERSHIPS', () => {
  return {
    url: '/membership/products',
  }
})

export const fetchMembership = createRequest('FETCH_MEMBERSHIP', (id) => {
  return {
    url: `membership/products/${id}`,
  }
})

export const fetchMembershipAccount = createRequest(
  'FETCH_MEMBERSHIP_ACCOUNT',
  () => {
    return {
      url: '/membership/account',
    }
  }
)

export const requestMemberLogin = createPostRequest(
  'MEMBER_LOGIN',
  (params) => ({
    url: 'login',
    body: params,
  })
)

export const registerMember = createPostRequest(
  'REGISTER_MEMBER',
  ({ newMember }) => ({
    url: '/membership/register',
    body: { member: newMember },
  })
)

export const requestPasswordReset = createPostRequest(
  'REQUEST_PASSWORD_RESET',
  (params) => ({
    url: '/membership/forgot-password',
    body: params,
  })
)

export const resetPassword = createPostRequest('RESET_PASSWORD', (params) => ({
  url: '/membership/reset-password',
  body: params,
}))

export const requestMemberDetails = createPostRequest(
  'MEMBER_DETAILS',
  (params) => ({
    url: 'forgot_login',
    body: params,
    successDataPath: '',
  })
)

export const fetchMembershipCart = createRequest(
  'FETCH_MEMBERSHIP_CART',
  () => {
    return {
      url: '/membership/cart',
    }
  }
)

export const validateMembershipCoupon = createPostRequest(
  'VALIDATE_MEMBERSHIP_COUPON',
  ({ coupon }) => ({
    url: `/membership/coupons/validate`,
    body: {
      coupon,
    },
    onFailure: ({ errors }) => ({
      errors: {
        coupon: errors.message || 'This coupon is invalid',
        cart: errors.membershipCart,
      },
    }),
  })
)

export const removeMembershipCoupon = createPostRequest(
  'REMOVE_TICKET_COUPON',
  () => ({
    url: `/membership/cart`,
    body: {
      cart: {
        coupon: null,
      },
    },
  })
)

export const saveMembershipDetailsToCart = createPostRequest(
  'SAVE_MEMBERSHIP_DETAILS_TO_CART',
  (cart, membershipTypes, params) => ({
    url: '/membership/cart',
    body: {
      cart: {
        ...params,
        ...mapUpdateMembershipQuantities(cart, membershipTypes, params),
      },
    },
    successDataPath: 'data',
  })
)

// By default this skips validation -- only use this for intermediary steps (not checkout)
export const updateMembershipCartQuantities = createPostRequest(
  'UPDATE_MEMBERSHIP_CART_QUANTITIES',
  (cart, membershipTypes, params) => ({
    url: '/membership/cart',
    body: {
      cart: {
        ...params,
        ...mapUpdateMembershipQuantities(cart, membershipTypes, params),
      },
    },
    query: {
      skipValidation: true,
    },
    successDataPath: 'data',
  })
)

export const updateAddOnMembershipCartQuantities = createPostRequest(
  'UPDATE_ADD_ON_MEMBERSHIP_CART_QUANTITIES',
  (cart, membershipTypes, params) => ({
    url: '/membership/cart',
    body: {
      cart: {
        ...params,
        ...mapUpdateMembershipQuantities(cart, membershipTypes, params),
      },
    },
  })
)

export const updateMembershipCartPayment = createPostRequest(
  'UPDATE_MEMBERSHIP_CART_PAYMENT',
  ({ customerDetails, cardReference, cart }) => ({
    url: `/membership/cart`,
    body: {
      cart: {
        ...cart,
        customerDetails,
        cardReference,
      },
    },
    successDataPath: 'data',
  })
)

export const createMembershipOrder = createPostRequest(
  'CREATE_MEMBERSHIP_ORDER',
  () => ({
    url: `/membership/orders`,
    body: {},
    successDataPath: 'data',
  })
)

export const createMembershipMembers = createPostRequest(
  'CREATE_MEMBERSHIP_MEMBERS',
  () => ({
    url: '/membership/members',
    body: {},
    successDataPath: 'data',
  })
)

// ------ DONATION ------

export const fetchDonationProducts = createRequest(
  'FETCH_DONATION_PRODUCTS',
  () => {
    return {
      url: `/donations/products`,
    }
  }
)

export const fetchDonationProduct = createRequest(
  'FETCH_DONATION_PRODUCT',
  (id) => {
    return {
      url: `/donations/products/${id}`,
    }
  }
)

export const fetchDonationCart = createRequest('FETCH_DONATION_CART', () => {
  return {
    url: `/donations/cart`,
  }
})

export const addDonationTypeToCart = createPostRequest(
  'ADD_DONATION_TYPE_TO_CART',
  (donation) => {
    return {
      url: `/donations/cart`,
      successDataPath: 'data',
      body: {
        cart: {
          productId: donation.id,
        },
      },
    }
  }
)

export const updateDonationCart = createPostRequest(
  'UPDATE_DONATION_CART',
  (params) => {
    return {
      url: `/donations/cart`,
      successDataPath: 'data.attributes',
      body: {
        cart: params,
      },
    }
  }
)

export const updateDonationCartPayment = createPostRequest(
  'UPDATE_DONATION_CART_PAYMENT',
  ({ customerDetails, cardReference, cart }) => {
    return {
      url: `/donations/cart`,
      successDataPath: 'data',
      body: {
        cart: {
          ...cart,
          customerDetails,
          cardReference,
        },
      },
    }
  }
)

export const createDonationOrder = createPostRequest(
  'CREATE_DONATION_ORDER',
  () => {
    return {
      url: `/donations/orders`,
    }
  }
)

// ------ OPTIONAL MODAL ADD ONS ------

export const addOrRemoveModalProduct = createPostRequest(
  'ADD_OR_REMOVE_MODAL_PRODUCT',
  (cart, productModalData) => {
    const modalType = productModalData.modalType
    const price =
      productModalData[`${modalType}-custom`] || productModalData[modalType]

    const requestKey = `${modalType}Modal`
    const cartData = {}
    cartData[requestKey] = {
      price,
      quantity: price >= 0.01 ? 1 : 0,
      ...productModalData,
    }

    const listItems = cart.listItems
    const cartType = getCartType(listItems)
    return {
      url: `/${cartType}/cart`,
      body: {
        cart: cartData,
      },
    }
  }
)

const getCartType = (listItems) => {
  if (isEmpty(listItems)) return Types.cartTypes.TICKETING
  const resourceType = first(listItems).resourceType.toUpperCase()
  return Types.resourceTypeNamespace[resourceType]
}
// ------ DUMMY REQUEST FOR ORG CONFIG ------

export const fetchOrgConfig = createStubRequest(
  'FETCH_ORG_CONFIG',
  getConfigForOrg
)

// ----- HELPER FUNCTION FOR TESTING FAILURES -----

export const stubFailure = (status, statusText, response, errors) => {
  return new Promise((_, reject) => {
    const error = new HttpError(status, statusText, response, errors)
    reject(error)
  })
}
