// values for mystic seaport RACE_ETH udf

export const raceEthnicityOptions = [
  {
    key: 'American Indian or Alaskan Native',
    value: 'American Indian or Alaskan Native',
  },
  {
    key: 'Asian',
    value: 'Asian',
  },
  {
    key: 'Black or African American',
    value: 'Black or African American',
  },
  {
    key: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    key: 'I Decline to answer.',
    value: 'I Decline to answer.',
  },
  {
    key: 'Native Hawaiian or alt Pacific Islander',
    value: 'Native Hawaiian or alt Pacific Islander',
  },
  {
    key: 'Other',
    value: 'Other',
  },
  {
    key: 'White ',
    value: 'White ', // note: need to keep space to match centaman picklist value on submit
  },
]

export default raceEthnicityOptions
