import React from 'react'
import PropTypes from 'prop-types'
import { displayCurrency } from 'utils'
import { LoadingContainer } from 'lp-components'
import { isNil } from 'lodash'
import { ToggleTip } from 'components'

const propTypes = {
  title: PropTypes.string,
  cart: PropTypes.object,
  image: PropTypes.string,
  displayTotal: PropTypes.bool,
  isCartLoading: PropTypes.bool,
}

const defaultProps = {
  title: 'Details',
  cart: {
    listItems: [],
    subtotalBeforeDiscounts: 0,
    taxes: 0,
    total: 0,
  },
  image: '',
  displayTotal: false,
  isCartLoading: false,
}

// Utility function to make up for the fact that Lodash's `isNumber` returns false for floats serialized as strings, e.g., "38.45"
const isNumber = (value) => {
  return !isNil(value) && !isNaN(Number(value))
}

function Receipt({ title, image, cart, displayTotal, isCartLoading }) {
  const {
    listItems = [],
    subtotalBeforeDiscounts = 0,
    taxes = 0,
    total = 0,
    totalSavings = 0,
  } = cart

  return (
    <div className="receipt">
      <div className="item-type">
        <div className="image" style={{ backgroundImage: `url(${image})` }} />
        <h2>{title}</h2>
      </div>
      <div className="subtotal receipt-edge">
        {/* Lower opacity of receipt items and prices when cart is updating */}
        <LoadingContainer isLoading={isCartLoading}>
          {listItems &&
            listItems.map(
              (item) =>
                !!item.quantity && (
                  <span key={item.centamanId} className="bill-item">
                    <p className="bill-item-name">
                      {item.displayName} x {item.quantity}
                    </p>
                    <p className="price">
                      {displayCurrency(item.subtotalBeforeDiscounts, {
                        displayFree: true,
                      })}
                    </p>
                    {item.isTransactionFee && item.infoText && (
                      <ToggleTip content={item.infoText} allowHTML>
                        {
                          <button
                            className="toggletip toggletip-small"
                            type="button"
                          >
                            ?
                          </button>
                        }
                      </ToggleTip>
                    )}
                    <br />
                  </span>
                )
            )}
          <hr />
          <div>
            {isNumber(subtotalBeforeDiscounts) && (
              <span className="bill-item">
                <p>Subtotal</p>
                <p className="price">
                  {displayCurrency(subtotalBeforeDiscounts)}
                </p>
              </span>
            )}
            {isNumber(totalSavings) && displayTotal && totalSavings > 0 && (
              <div className="bill-item promo-item">
                <p>Promotion Savings</p>
                <p className="price">{`- ${displayCurrency(totalSavings)}`}</p>
              </div>
            )}
            {isNumber(total) && displayTotal && (
              <div>
                <span className="bill-item">
                  <p>Tax</p>
                  <p className="price">{displayCurrency(taxes)}</p>
                  <br />
                </span>
                <span className="bill-item subtotal-price">
                  <p>Total</p>
                  <p className="price">{displayCurrency(total)}</p>
                </span>
              </div>
            )}
          </div>
        </LoadingContainer>
      </div>
      <span
        role="log"
        className="visually-hidden"
        aria-live="polite"
        aria-atomic="true"
      >
        Cart total: {displayCurrency(total)}
      </span>
    </div>
  )
}

Receipt.propTypes = propTypes
Receipt.defaultProps = defaultProps

export default Receipt
