import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'
import * as Views from './views'
import StepLayout from './StepLayout'

const Routes = (
  <React.Fragment>
    <Route path="events" component={Views.SelectEvent} />
    <Route path="merchandise" component={Views.SelectMerchandise} />
    <Route path="tickets">
      <IndexRoute component={Views.SelectTicket} />
      <Route component={StepLayout}>
        <Route path="details(/:ticketId)" component={Views.TicketDetails} />
        <Route path="add-ons" component={Views.SelectAddOns} />
        <Route path="payment" component={Views.TicketPaymentInfo} />
      </Route>
      <Route path="confirmation" component={Views.TicketConfirmation} />
      <Redirect from="*" to="/tickets" />
    </Route>
  </React.Fragment>
)

export default Routes
