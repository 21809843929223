import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { LabeledField } from 'lp-components'
import { onMount } from 'lp-hoc'
import classnames from 'classnames'
import { runExternalScript } from 'utils'

const propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['compact', 'normal']),
  theme: PropTypes.oneOf(['light', 'dark']),
}

const defaultProps = {
  size: 'normal',
  theme: 'light',
}

// eslint-disable-next-line
function HideableArea({ children, hidden }) {
  const style = hidden ? { display: 'none', ariaHidden: true } : {}
  return <div style={style}>{children}</div>
}

function RecaptchaInput({ size, theme, loading, ...rest }) {
  const {
    meta: { touched, invalid },
  } = rest
  return (
    <React.Fragment>
      {loading && <p>Loading reCAPTCHA...</p>}
      <HideableArea hidden={loading}>
        <LabeledField {...rest}>
          <div
            id="recaptcha"
            className={classnames('g-recaptcha', { error: touched && invalid })}
            data-size={size}
            data-theme={theme}
          />
        </LabeledField>
      </HideableArea>
    </React.Fragment>
  )
}

RecaptchaInput.propTypes = propTypes
RecaptchaInput.defaultProps = defaultProps

function initializeRecaptcha({ input: { onChange }, setLoading }) {
  // Recaptcha render function is not available when script fires native "onload" function
  window.onloadCallback = () => {
    window.grecaptcha.render('recaptcha', {
      sitekey: process.env.GOOGLE_RECAPTCHA_KEY,
      callback: onChange,
      'expired-callback': () => onChange(''),
    })

    setLoading(false)
  }
  runExternalScript(
    'https://www.recaptcha.net/recaptcha/api.js?onload=onloadCallback&render=explicit'
  )
}

export default compose(
  withState('loading', 'setLoading', true),
  onMount(initializeRecaptcha)
)(RecaptchaInput)
