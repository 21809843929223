import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { BillingDetailsForm } from 'forms'
import { selectors } from '../reducer'
import { push } from 'react-router-redux'
import { modifyProps, onMount } from 'lp-hoc'
import { getPaymentProcessor } from 'payment'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import * as actions from '../actions'
import {
  flashInvalidFormSubmitMessage,
  mapAdditionalCartFields,
  redirectUnless,
} from 'utils'
import { StickyContainer, MainHeader, Receipt } from 'components'
import { clearMessages } from 'redux-flash'

const propTypes = {
  cart: PropTypes.object,
  redirectToNextStep: PropTypes.func.isRequired,
  selectedDonation: PropTypes.object.isRequired,
  stepName: PropTypes.string.isRequired,
  submitOrder: PropTypes.func.isRequired,
  paymentProcessor: PropTypes.object.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

function DonationPaymentInfo({
  cart,
  paymentProcessor,
  redirectToNextStep,
  selectedDonation: { displayName, image },
  stepName,
  submitOrder,
  clearMessages,
}) {
  return (
    <div className="step-container">
      <MainHeader title={stepName} />
      <div className="row">
        <div className="col-8">
          <BillingDetailsForm
            name={Types.billingFormTypes.DONATION}
            initialValues={{ donationUdfs: cart.donationUdfs }}
            paymentProcessor={paymentProcessor}
            onSubmit={(params) => {
              clearMessages()
              return submitOrder(params)
            }}
            onSubmitSuccess={redirectToNextStep}
            onSubmitFail={flashInvalidFormSubmitMessage}
          />
        </div>
        <StickyContainer className="col-4">
          <Receipt title={displayName} cart={cart} image={image} displayTotal />
        </StickyContainer>
      </div>
    </div>
  )
}

DonationPaymentInfo.propTypes = propTypes
DonationPaymentInfo.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    organization: globalSelectors.organization(state),
    selectedDonation: selectors.selectedDonation(state),
    webstore: globalSelectors.webstore(state),
    nextStepPath: selectors.nextStepPath(state),
    stepName: selectors.currentStepName(state),
  }
}

const mapDispatchToProps = {
  createOrder: apiActions.createDonationOrder,
  emptyCart: actions.emptyCart,
  push,
  updateCartPayment: apiActions.updateDonationCartPayment,
  clearMessages,
}

const initializeOrgHelpers = ({ organization }) => {
  return {
    paymentProcessor: getPaymentProcessor(organization.paymentConfig.service),
  }
}

const initializePaymentProcessor = ({ organization, paymentProcessor }) => {
  return paymentProcessor.init(
    organization.paymentConfig.envVariables.environmentKey
  )
}

const modify = ({
  cart,
  createOrder,
  emptyCart,
  nextStepPath,
  paymentProcessor,
  push,
  updateCartPayment,
  webstore,
}) => {
  return {
    redirectToNextStep: () => push(`/${webstore}/donate/${nextStepPath}`),
    submitOrder: (params) => {
      return paymentProcessor
        .submitPaymentDetails(cart.total, params.paymentDetails)
        .then(({ cardReference, cardType, lastFourDigits }) =>
          updateCartPayment({
            customerDetails: {
              ...params.customerDetails,
              cardType,
              lastFourDigits,
            },
            cardReference,
            cart: mapAdditionalCartFields(cart, params),
          })
        )
        .then(createOrder)
        .then(() => {
          LS.clearCartToken()
          emptyCart()
        })
    },
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  redirectUnless(
    ({ cart, selectedDonation }) => {
      return selectedDonation && cart.priceSelections.length
    },
    ({ webstore }) => `/${webstore}/donate`
  ),
  modifyProps(initializeOrgHelpers),
  onMount(initializePaymentProcessor),
  modifyProps(modify)
)(DonationPaymentInfo)
